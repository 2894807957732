@charset 'utf-8';

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'thin' {
    @media (min-width: 375px) {
      @content;
    }
  } @else if $breakpoint == 'small' {
    @media (min-width: 390px) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'big' {
    @media (min-width: 1920px) {
      @content;
    }
  }
}

.footer {
  @include respond-to('thin') {
    height: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4e5254;
  }
  @include respond-to('small') {
    padding: 20px 0;
  }

  @include respond-to('big') {
    height: 240px;
  }

  &__inner {
    @include respond-to('thin') {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    @include respond-to('big') {
      max-width: 1760px;
      flex-direction: unset;
    }

    &__logoBox {
      @include respond-to('thin') {
        margin-top: 20px;
      }
      @include respond-to('big') {
        width: 15%;
        margin-top: unset;
      }

      &-logo {
        @include respond-to('thin') {
          width: 200px;
          height: 50px;
          line-height: 50px;
          background: url(./images/ione_footer_logo.png) no-repeat center/cover;
        }
        @include respond-to('big') {
          filter: brightness(100);
        }
      }
    }

    &__contentBox {
      @include respond-to('thin') {
        color: #fff !important;
        margin-top: 30px;
        width: 100%;
        font-size: 1rem;
      }

      @include respond-to('big') {
        margin-left: 30px;
        margin-top: unset;
        width: unset;
      }

      dl {
        @include respond-to('thin') {
          position: relative;
          display: block;
          text-align: center;
        }

        @include respond-to('big') {
          text-align: unset;
          display: inline-block;

          &:nth-of-type(2) {
            display: block;
            margin-top: 10px;
          }
        }

        dt {

          @include respond-to('thin') {
            margin: .5rem 0;
          }
          @include respond-to('big') {
            display: inline-block;
            margin: 0 10px;
          }
        }
        dd {
          @include respond-to('thin') {
            margin: .5rem 0;
          }
          @include respond-to('big') {
            display: inline-block;
            margin: 0 10px;

            &::before {
              content: '|';
              display: inline-block;
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
}
